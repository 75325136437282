body {
  background-color: #f9f9f9;
  font-size: 15px;
}
.App {}

amplify-s3-image {
  --width: auto;
  --height: 123px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  text-align: center;
  box-sizing: border-box;
}

#selling-container amplify-s3-image {
  --width: 75px;
  --height: auto;
}

#home-wrapper amplify-s3-image {
  --width: 100%;
}

.infinite-scroll-wrapper amplify-s3-image {
  --width: 100%;
}

.carousel-item img {
  height: 420px;
  min-height: 300px;
  width: auto;
  object-fit: contain;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 44px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header h2 {
  margin: 0;
}

.App-link {
  color: #61dafb;
}

/* card */
.card-item {
  height: 250px;
  border: 1px solid #eee;
  width: 200px;
  box-sizing: border-box;
  float: left;
  margin-bottom: 5px;
}

#search-wrapper form {
  width: 100%;
  text-align: left;
  font-size: 18px;
}
.sui-search-box__text-input {
  padding: 12px 16px;
  font-size: 18px;
}

.btn-color-muted {
  color: #949494;
  background-color: #fff;
  border-color: #ccc;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -15px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 15px; /* gutter size */
  background-clip: padding-box;
}
 
/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 15px;
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

@media (max-width: 414px) {
  .container #jumbotron {
      font-size: 1.8rem;
  }
  .card-item {
      width: 49%;
      margin-bottom: 5px;
  }
  .home-col-6.col-6 {
      padding: 2.5px;
      width: 48%;
  }
  .row {
      padding-left: -10px;
      padding-right: -10px;
  }

  .inventory-header {
    flex-direction: column;
  }

  amplify-s3-image {
    --width: 125px;
    --height: auto;
    overflow: hidden;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .card-columns {
    column-count: 2;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .card-columns {
    column-count: 2;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container-details {
      max-width: 750px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container {
      max-width: 1050px;
  }
  .container-details {
      max-width: 750px;
      margin-top: 10px;
  }
  #search-container {
      background-position-y: -440px;
  }
  .card-columns {
    column-count: 4;
  }
  .home.card-columns {
    column-count: 3;
  }
}

/** UTILITIE CSS CLASSES **/
.navbar-link {
  color: #5a5454 !important;
  font-weight: 600;
}

.nav-link {
  color: #5a5454 !important;
  font-weight: 600;
  cursor: pointer;
}

.help-block {
  font-size: 12px;
  color: #636363;
  margin-top: 6px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-link {
  color: #505050 !important;
}
.pagination .active a {
  background-color: #eee !important;
}

.toast-container {
  border-radius: 4px;
}

/* Used in the sidebar */
.sidebar-links .active {
  border-left: 4px solid #fd0000 !important;
  background: #eee;
}
.sidebar-links .nav-item {
  border-left: 4px solid transparent;
}

/* used in DataTable */
.filter-selected {
  background: #f5f5f5;
  border-radius: 6px;
}

.nav-link-active {
  border-bottom: 3px solid #696969;
}

.breadcrumb {
  padding: .35rem 1rem !important;
  list-style: none;
  background-color: transparent;
  border-radius: .25rem;
  margin-bottom: 0;
}

.container-wrapper {
  margin-top: 60px;
}

.carousel-controller {
  width: 100%;
}

.font-size-14 {
  font-size: 14px;
}